import React, { Component } from "react";
import Fade from "react-reveal";

class Portfolio extends Component {
  render() {
    if (!this.props.data) return null;

    return (
      <section id="portfolio">
        <Fade left duration={1000} distance="40px">
          <div className="row">
            <div>
              <h1>Check Out Some of Our Work</h1>
              <iframe
                className="google-drive-iframe"
                title="images"
                src="https://drive.google.com/embeddedfolderview?id=12Xvn13ZXziNjLYfuW00g7_wYNr-OrdIv#grid"
              ></iframe>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default Portfolio;
