import React, { Component } from "react";
import Fade from "react-reveal";
import logo from '../assets/sv&j-logo.png'

class About extends Component {
  render() {
    if (!this.props.data) return null;

    // const name = this.props.data.name;
    // const profilepic = "images/" + this.props.data.image;
    const bio = this.props.data.bio;
    // const street = this.props.data.address.street;
    // const city = this.props.data.address.city;
    // const state = this.props.data.address.state;
    // const zip = this.props.data.address.zip;
    // const phone = this.props.data.phone;
    // const email = this.props.data.email;

    return (
      <section id="about">
        <Fade duration={1000}>
          <div className="row">
            <div className="three columns">
              <img
                className=""
                src={logo}
                alt="Profile Pic"
              />
            </div>
            <div className="nine columns main-col">
              <h2>About our Services</h2>

              <h5>{bio}</h5>
              <div className="row">
                {/* <div className="columns contact-details">
                  <h2>Contact Details</h2>
                  <p className="address">
                    <span>{name}</span>
                    <br />
                    <span>
                      {street}
                      {city}, {state} {zip}
                    </span>
                    <br />
                    <span>{phone}</span>
                    <br />
                    <span>{email}</span>
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default About;
